/**=====================
     50. Tree  CSS Start
==========================**/
.jstree-default{
	.jstree-clicked{
		background-color: lighten($primary-color , 40%);
		box-shadow: none;
		color: $dark-color;
	}
	i.jstree-icon.jstree-checkbox.jstree-undetermined{
		position: relative;
		&:before {
			content: "";
			position: absolute;
			height: 10px;
			width: 10px;
			background-color: $primary-color;
			top: 7px;
			left: 7px;
		}
	}
}
.vakata-context, .vakata-context ul{
	background-color: $white;
	border: none;
	box-shadow: 0 0 2px 2px $light-semi-gray;
	 li{
		 a{
			 .vakata-contextmenu-sep{
				 display: none;
			 }
			 &:hover{
				 background-color: lighten($primary-color , 45%);
				 box-shadow: None;
			 }
		}
	}
	.vakata-context-separator{
		display: none;
	}
}

/**=====================
     50. Tree CSS Ends
==========================**/