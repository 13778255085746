/**=====================
     32. pagination CSS Start
==========================**/
/*test Nav bar color */
$page-name:theme,
primary,
secondary,
success,
danger,
info,
dark,
warning;
$page-color:$primary-color,
$primary-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$dark-color,
$warning-color;
@each $var in $page-name {
  $i: index($page-name, $var);
  .pagination-#{$var}{
    .page-item{
      .page-link{
        color: nth($page-color, $i);
      }
      &.active{
        .page-link{
          color: $white;
          background-color:  nth($page-color, $i);
          border-color:  nth($page-color, $i);
        }
      }
    }
  }
}
/**=====================
     32. pagination CSS Ends
==========================**/